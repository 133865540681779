'use client';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { useSnackbar } from 'notistack';
import { MenuItem, Modal, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import dayjs from 'dayjs';
import { Auth, useAuth } from '@/hooks/useAuth';
import Link from 'next/link';

type Campaign = {
  campaign_id: string;
  campaign_project_items: CampaignProjectItem[];
};

type Interview = {
  project_id: string;
  status: string;
};

type CampaignPastData = {
  interviews_list: Interview[];
};

type Data = {
  campaigns: Campaign[];
  campaigns_past_data: {
    [key: string]: CampaignPastData;
  };
};

type InterviewTypeCount = {
  [key: string]: {
    interview_count: number;
    interviews: CampaignProjectItem[]
    display_name: string;
  };
};

interface IFormInput {
  interview_type: string;
  difficulty: string;
  credits: number;
}

interface PastInterviewData {
  chat_text: string[];
  editor_content: string;
  evaluation: Evaluation;
  qn: number;
  question: string;
}

interface Evaluation {
  dimensional_scores: DimensionalScore[];
  is_hire: boolean;
  score: number;
  summary: string;
}

interface DimensionalScore {
  dimension: string;
  explanation: string;
  score: number;
}

export default function Home() {
  const { authDetails, isAuthenticated, subCredits } = useAuth();
  const router = useRouter();
  const [campaignsDetails, setCampaignsDetails] = useState<Campaign>({} as Campaign);
  const [campaignProjectItems, setCampaign_project_items] = useState<InterviewTypeCount>({});
  const [campaignHistoryProjectItems, setHistoryCampaignProjectItems] = useState<InterviewData[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState<IFormInput>({
    interview_type: '',
    difficulty: '',
    credits: 0,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [topics, setTopics] = React.useState<string[]>([]);

  const DIFFICULTY = ['Easy', 'Medium', 'Hard'];
  const TOPICS: { [key: string]: string[] } = {
    'Technical': ['Array', 'LinkedList', 'Tree', 'String', 'Stack', 'Queue'],
    'Language': ['Golang'],
    'Azure900': ['Cloud Concepts',
      'Architecture and Services',
      'Management and Governance'],
    'ProjectManager': ['Communication Skills',
      'Problem Solving',
      'Teamwork',
      'Leadership',
      'Adaptability',
      'Culture Fit',
      'Empathy'],
  };
  const handleTopicChange = (event: SelectChangeEvent<typeof topics>) => {
    const {
      target: { value },
    } = event;
    setTopics(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function getReport(interview: PastInterviewData[], status: string) {
    if (status === 'pending') {
      return '';
    } else {
      if (interview.length === 0) {
        return 'No Report';
      } else {
        return interview[0].evaluation.score;

      }
      // try {
      //   const report = "";
      //   fetch("/api/interview/" + project_id + "/" + interview_id + "/report", {
      //     method: "GET",
      //     headers: {
      //       "Accept": "application/json",
      //       "Authorization": `Bearer ${authDetails.access_token}`
      //     }
      //   }).then((res) => res.json()).then((val) => {
      //     console.log(val);
      //   });
      //   return report;
      // } catch (e: any) {
      //   console.log("ERROR " + e);
      //   return "error";
      // }

    }

  }

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // @ts-ignore
    if (campaignProjectItems[selectedCampaign]?.interviews.find((item) => item.id === formData.difficulty)?.per_interview_credits > authDetails.credits) {
      enqueueSnackbar('Insufficient credits, please recharge your account', { variant: 'error' });
      return;
    }
    if (formData.difficulty === '') {
      enqueueSnackbar('Select the difficulty', { variant: 'error' });
      return;
    }
    if (topics.length === 0) {
      enqueueSnackbar('Select the topics', { variant: 'error' });
      return;
    }
    const credit = campaignProjectItems[selectedCampaign]?.interviews[0].per_interview_credits;
    try {
      setSubmitted(true);
      await scheduleInterview(formData.difficulty, campaignsDetails.campaign_id, credit, topics, -1);
      setCampaign_project_items((v) => {
        return {
          ...v,
          [selectedCampaign]: {
            ...v[selectedCampaign],
            interview_count: v[selectedCampaign].interview_count + 1,
          },
        };
      });
      handleModalClose();
    } catch {
      enqueueSnackbar('Failed to schedule interview', { variant: 'error' });
    } finally {
      setSubmitted(false);
    }
  };

  async function scheduleInterview(project_id: string, campaign_id: string, credits: number, topics: string[], start_time = -1) {
    // TODO ADD the TOPICS in API @Utkarsh
    console.log(JSON.stringify({
      topics: topics,
      difficulty: formData.difficulty,
    }));
    const res = await fetch(`/api/interview/schedule-campaign`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authDetails.access_token}`,
      },
      body: JSON.stringify({
        topics,
        difficulty: formData.difficulty,
        project_id,
        campaign_id,
        start_time,
      }),
    });
    if (res.ok) {
      const data = await res.json();
      setHistoryCampaignProjectItems((v) => [...v, data.data]);
      subCredits(credits);
      alert('Interview Scheduled Successfully');
    } else {
      const data = await res.json();
      if (data.detail.error === 'Insufficient credits, please recharge your account') {
        enqueueSnackbar(data.detail.error, { variant: 'error' });
      }
    }
  }

// TO Calculate the Number of Interviews Taken and parse the data to get the interview type
  function getInterviewTypeCount(data: Data): InterviewTypeCount {
    const interviewTypeCount: InterviewTypeCount = {
      'Azure900': {
        interview_count: 0,
        interviews: [],
        display_name: 'Azure Fundamentals',

      },
      'Technical': {
        interview_count: 0,
        interviews: [],
        display_name: 'Coding & Problem solving',
      },
      'Language': {
        interview_count: 0,
        interviews: [],
        display_name: 'Language Proficiency',

      }, 'ProjectManager': {
        interview_count: 0,
        interviews: [],
        display_name: 'Project Manager',

      },
    };

    // Iterate over each campaign in the data
    data.campaigns.forEach((campaign) => {
      interviewTypeCount['Technical'].interviews = campaign.campaign_project_items.filter(value =>
        value.inflated_data.interview_type === 'Technical',
      );
      interviewTypeCount['Language'].interviews = campaign.campaign_project_items.filter(value =>
        value.inflated_data.interview_type === 'Language Proficiency',
      );
      interviewTypeCount['Azure900'].interviews = campaign.campaign_project_items.filter(value =>
        value.inflated_data.interview_type === 'Azure900',
      );
      interviewTypeCount['ProjectManager'].interviews = campaign.campaign_project_items.filter(value =>
        value.inflated_data.interview_type === 'ProjectManager',
      );
      const pastData = data.campaigns_past_data[campaign.campaign_id];

      if (pastData) {
        const pastInterviews = pastData.interviews_list;

        pastInterviews.forEach((interview) => {
          // Check project_id to determine the interview type
          if (interview.project_id.includes('t')) {
            // Increment the count for technical interviews
            interviewTypeCount['Technical'].interview_count += 1;
          } else if (interview.project_id.includes('l')) {
            // Increment the count for product manager interviews
            interviewTypeCount['Language'].interview_count += 1;
          } else if (interview.project_id.includes('p')) {
            // Increment the count for product manager interviews
            interviewTypeCount['ProjectManager'].interview_count += 1;
          } else if (interview.project_id.includes('a')) {
            // Increment the count for product manager interviews
            interviewTypeCount['Azure900'].interview_count += 1;
          }
        });
      }
    });

    return interviewTypeCount;
  }

  async function fetchCampaigns() {
    if (!authDetails) return;
    if (authDetails) {
      const res = await fetch(`/api/campaign/me`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authDetails.access_token}`,
        },
      });
      if (res.ok) {
        const data = await res.json();
        setCampaignsDetails(data.data.campaigns[0]);
        setCampaign_project_items(getInterviewTypeCount(data.data));
        setHistoryCampaignProjectItems(data.data.campaigns_past_data['mssavet'].interviews_list);

      } else {
        console.error(res);
      }
    }
  }

  useEffect(() => {
    if (isAuthenticated === Auth.authenticated) {
      fetchCampaigns();
    }
  }, [isAuthenticated, authDetails]);

  if (isAuthenticated === Auth.unAuthenticated) {
    router.push('/signin');
    return <>Redirecting to Signin Page</>;
  }

  function handleModalClose() {
    setOpenModal(false);
  }

  return (
    <>
      <div className="relative p-1 pt-16 lg:p-16 overflow-x-auto bg-gray-50">
        <h1 className="py-6 text-2xl font-bold text-gray-800 dark:text-gray-100">My Interviews</h1>
        <div className="relative overflow-x-auto rounded-lg p-0 py-4 shadow-md antialiased">
          <table className="bg-white w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Assessment Type</th>
              <th scope="col" className="px-6 py-3">Skill Level</th>
              <th scope="col" className="px-6 py-3">Interviews Taken</th>
              <th scope="col" className="px-6 py-3">Uplevel me</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(campaignProjectItems).map((value, index) => (
              <tr key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{campaignProjectItems[value].display_name}</th>
                <td className="px-6 py-4">
                  <div className="flex">
                    <span className="bg-red-500 w-4 h-4 rounded-full"></span>
                    <span className="ml-2 text-sm text-gray-500 dark:text-gray-400">Beginner</span>
                  </div>
                </td>
                <td className="px-6 py-4">{campaignProjectItems[value].interview_count}</td>
                <td className="px-6 py-4">
                  <div className="flex item center gap-2">
                    <button
                      onClick={() => {
                        setSelectedCampaign(value);
                        setTopics([TOPICS[value][0]]);
                        setOpenModal(true);
                      }}
                      className={'rounded backdrop-blur bg-gray-100 hover:bg-gray-600 hover:text-white dark:bg-gray-600 p-2'}>Add
                      Interview
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        <h1 className="pb-6 pt-10 text-2xl font-bold text-gray-800 dark:text-gray-100">My Assessments</h1>
        <div className="relative overflow-x-auto rounded-lg p-0 py-4 shadow-md antialiased">
          <table className="bg-white w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Assessment Type</th>
              <th scope="col" className="px-6 py-3">Date</th>
              <th scope="col" className="px-6 py-3">Topics</th>
              <th scope="col" className="px-6 py-3">Interview Link</th>
              <th scope="col" className="px-6 py-3">Report Status</th>
              <th scope="col" className="px-6 py-3">Score</th>

            </tr>
            </thead>
            <tbody>
            {campaignHistoryProjectItems.map((value: InterviewData, index) => (
              <tr key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {parseInterviewType(value.project_id)}
                </th>
                <td className="px-6 py-4">{parseUnixTime(value.interview_start_at)}</td>
                <td className="px-6 py-4">
                  <div className="flex flex-wrap justify-start max-w-[200px]">
                    {value.for_skills.map((skill: string, index: number) => <div key={index}
                                                                                 className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white hover:bg-green-100 cursor-pointer rounded-full text-gray-700 border border-gray-300 ">
                      <div className="text-xs font-normal leading-none max-w-full flex-initial">{skill}</div>
                    </div>)}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <Link href={value.interview_link}
                        className={'rounded backdrop-blur bg-gray-100 hover:bg-gray-600 hover:text-white dark:bg-gray-600 p-2'}>Click
                    here</Link>
                </td>
                <td className="px-6 py-4">
                  {value.status === 'pending' ? (
                    <div className="flex items-center gap-1">
                      <ReportIcon />
                      <span className="text-gray-500 dark:text-gray-400">{value.status.toString().toUpperCase()}</span>
                    </div>
                  ) : (
                    <Link
                      className={'inline-flex items-center gap-1 rounded backdrop-blur bg-gray-100 hover:bg-gray-600 hover:text-white dark:bg-gray-600 p-2'}
                      href={value.interview_link.replace('interview', 'score')}>
                      <ReportIcon />
                      <span className="text-gray-500 dark:text-gray-400 hover:text-white">View Report</span>
                    </Link>
                  )}
                </td>
                <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {getReport(value.finished_interview_data, value.status)}
                </td>
              </tr>

            ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal open={openModal} onClose={handleModalClose} className={'flex items-center justify-center'}>
        <div className="shadow-three mx-auto max-w-[500px] rounded bg-white px-6 py-10 dark:bg-dark sm:p-[60px]">
          <h3 className="mb-3 text-center text-2xl font-bold text-black dark:text-white sm:text-3xl">Interview
            Details</h3>
          <p className="mb-11 text-center text-base font-medium text-body-color">Choose the difficulty level of the
            interview and click on Add Interview</p>
          <form onSubmit={onSubmit}>
            <div className="mb-8">
              <label htmlFor="interview type" className="mb-3 block text-sm text-dark dark:text-white tracking-wide">Interview
                Type</label>
              <input type="text" placeholder="Interview Type"
                     className="border-stroke dark:text-body-color-dark dark:shadow-two w-full rounded-sm border bg-[#f8f8f8] px-6 py-3 text-base text-body-color outline-none transition-all duration-300 focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none"
                     value={selectedCampaign}
                     disabled
              />
            </div>
            <div className="mb-8">
              <label htmlFor="credits"
                     className="mb-3 block text-sm text-dark dark:text-white tracking-wide">Credits</label>
              <input type="text" placeholder="credits"
                     className="border-stroke dark:text-body-color-dark dark:shadow-two w-full rounded-sm border bg-[#f8f8f8] px-6 py-3 text-base text-body-color outline-none transition-all duration-300 focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none"
                     value={campaignProjectItems[selectedCampaign]?.interviews[0].per_interview_credits}
                     disabled
              />
            </div>
            <div className="mb-8">
              <label htmlFor="topics"
                     className="mb-3 block text-sm text-dark dark:text-white tracking-wide">Topics</label>
              <Select
                labelId="topics"
                id="topics"
                multiple
                input={<OutlinedInput
                  className={'border-stroke dark:text-body-color-dark dark:shadow-two w-full rounded-sm border bg-[#f8f8f8] px-6  text-base text-body-color outline-none transition-all duration-300 focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none'} />}
                value={topics}
                onChange={handleTopicChange}
                sx={{ width: '100%' }}
              >
                {selectedCampaign && TOPICS[selectedCampaign].map((value: string, index: number) =>
                  <MenuItem
                    key={index}
                    value={value}
                  >
                    {value}
                  </MenuItem>,
                )}

              </Select>
            </div>
            <div className="mb-8">
              <label htmlFor="difficulty" className="mb-3 block text-sm text-dark dark:text-white tracking-wide">Select
                the Difficulty Level</label>
              <select id="difficulty"
                      className={'border-stroke dark:text-body-color-dark dark:shadow-two w-full rounded-sm border bg-[#f8f8f8] px-6 py-3 text-base text-body-color outline-none transition-all duration-300 focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none'}
                      value={formData.difficulty}
                      onChange={(e) => setFormData({ ...formData, difficulty: e.target.value })}>
                <option className={'text-black text-wrap max-w-fit'} disabled value={''}>Select Difficulty</option>
                {selectedCampaign != null && campaignProjectItems[selectedCampaign]?.interviews.map((interview: CampaignProjectItem) => (
                  <option className={'text-black text-wrap max-w-fit'} key={interview.id}
                          value={interview.id}>{DIFFICULTY[interview.difficulty_points - 1]}</option>
                ))}
              </select>
            </div>
            <div className="mb-6">
              <button
                disabled={submitted}
                className={`shadow-submit dark:shadow-submit-dark flex w-full items-center justify-center rounded-sm px-9 py-4 text-base font-medium text-white duration-300 hover:bg-primary/90 ${submitted ? 'bg-gray-500' : 'bg-primary'}`}>Add
                Interview
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}


function CalendarIcon() {
  return (
    <svg
      className={'interview-date rounded backdrop-blur bg-gray-100 hover:bg-gray-600 hover:text-white dark:bg-gray-600 '}
      xmlns="http://www.w3.org/2000/svg" width="25px"
      viewBox="0 0 24 24">
      <path fill="currentColor"
            d="M5 7.5A1.5 1.5 0 0 1 6.5 6h11A1.5 1.5 0 0 1 19 7.5V9H5z" />
      <path fill="currentColor" fillRule="evenodd"
            d="M16 4.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5m-8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5M5 10h14v6.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 5 16.5zm10.5 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m-2.5-.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm-3.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5M7 13.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm8.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m-2.5-.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm-3.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5M7 15.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zm5.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m-2.5-.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"
            clip-rule="evenodd" />
    </svg>);
}

//
// interface ButtonFieldProps
//   extends UseDateFieldProps<Dayjs, false>,
//     BaseSingleInputFieldProps<
//       Dayjs | null,
//       Dayjs,
//       FieldSection,
//       false,
//       DateValidationError
//     > {
//   setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
// }
//
// function ButtonField(props: ButtonFieldProps) {
//   const {
//     setOpen,
//     id,
//     disabled,
//     InputProps: { ref } = {},
//     inputProps: { "aria-label": ariaLabel } = {}
//   } = props;
//
//   return (
//     <Button
//       variant="text"
//       id={id}
//       disabled={disabled}
//       ref={ref}
//       aria-label={ariaLabel}
//       sx={{
//         fill: "#000"
//       }}
//       onClick={() => setOpen?.((prev) => !prev)}
//     >
//       {/*{label ? `Current date: ${label}` : <CalendarIcon/>}*/}
//       <CalendarIcon />
//     </Button>
//   );
// }
//
// function ButtonDatePicker(
//   props: Omit<DateTimePickerProps<Dayjs>, "open" | "onOpen" | "onClose">
// ) {
//   const [open, setOpen] = React.useState(false);
//
//   return (
//     <DateTimePicker
//       slots={{ ...props.slots, field: ButtonField }}
//       slotProps={{ ...props.slotProps, field: { setOpen } as never }}
//       {...props}
//       open={open}
//       onClose={() => setOpen(false)}
//       onOpen={() => setOpen(true)}
//     />
//   );
// }
//
// function PickerWithButtonField({
//                                  campaign_id,
//                                  project_id,
//                                  credits,
//                                  scheduleInterview
//                                }: {
//   campaign_id: string,
//   project_id: string,
//   credits: number,
//   scheduleInterview: (project_id: string, campaign_id: string, credits: number, start_time?: number) => void
// }) {
//   function handleDateChange(newValue: Dayjs | null) {
//     console.log(newValue?.unix());
//     console.log(campaign_id);
//     console.log(project_id);
//     scheduleInterview(project_id, campaign_id, credits, newValue?.unix());
//   }
//
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <ButtonDatePicker
//         // label={value == null ? null : value.format('MM/DD/YYYY')}
//         // value={value}
//         onAccept={(newValue) => handleDateChange(newValue)}
//       />
//     </LocalizationProvider>
//   );
// }

function ReportIcon() {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24">
    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path strokeDasharray="64" strokeDashoffset="64" d="M13 3l6 6v12h-14v-18h8">
        <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="64;0" />
      </path>
      <path strokeDasharray="14" strokeDashoffset="14" strokeWidth="1" d="M12.5 3v5.5h6.5">
        <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="14;0" />
      </path>
      <path strokeDasharray="4" strokeDashoffset="4" d="M9 17v-3">
        <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.9s" dur="0.2s" values="4;0" />
      </path>
      <path strokeDasharray="6" strokeDashoffset="6" d="M12 17v-4">
        <animate fill="freeze" attributeName="stroke-dashoffset" begin="1.1s" dur="0.2s" values="6;0" />
      </path>
      <path strokeDasharray="6" strokeDashoffset="6" d="M15 17v-5">
        <animate fill="freeze" attributeName="stroke-dashoffset" begin="1.3s" dur="0.2s" values="6;0" />
      </path>
    </g>
  </svg>);
}

function parseInterviewType(interview: string) {
  if (interview.includes('t')) {
    // Increment the count for technical interviews
    return 'Coding & Problem solving';
  } else if (interview.includes('l')) {
    return 'Language Proficiency';
  } else if (interview.includes('p')) {
    // Increment the count for product manager interviews
    return 'Product Manager';
  } else if (interview.includes('a')) {
    // Increment the count for product manager interviews
    return 'Azure Fundamentals';
  }
}

function parseUnixTime(time: number) {
  return dayjs.unix(time).format('DD/MM/YYYY');
}
