import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";

export enum Auth {
  initial,
  authenticated,
  unAuthenticated,
}

interface UserProfile {
  user_name: string;
  email: string;
  password_hashed: string;
  company_name: string;
  is_verified: boolean;
  is_premium: boolean;
  is_admin: boolean;
  extra_data: Record<string, any>;
  campaign_ids: string[];
  credits: number;
}

interface AuthContextType {
  isAuthenticated: Auth | null;
  login: (details: any) => void;
  logout: () => void;
  authDetails: any;
  profile: UserProfile | null;
  subCredits: (credits: number) => void;
  updateCredits: (credits: number) => void;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);


export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<Auth>(Auth.initial);
  const [authDetails, setAuthDetails] = useState<any>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const router = useRouter();

  function subCredits(credits: number) {
    setProfile((prev) => {
      if (prev) {
        return {
          ...prev,
          credits: prev.credits - credits
        };
      }
      return null;
    });
  }

  function updateCredits(credits: number) {
    setProfile((prev) => {
      if (prev) {
        return {
          ...prev,
          credits
        };
      }
      return null;
    });
  }

  async function verifyAuth(details: {
    access_token: string;
    token_type: string;
  }) {
    const res = await fetch("api/auth/me", {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${details.access_token}`
      }
    });

    if (res.ok) {
      const data = await res.json();
      setAuthDetails(details);
      setIsAuthenticated(Auth.authenticated);
      setProfile(data);
    } else {
      const data = await res.json();
      console.log("User not authenticated");
      setIsAuthenticated(Auth.unAuthenticated);
      console.error(data);
    }
  }

  useEffect(() => {
    const storedAuthDetails = localStorage.getItem("authDetails");
    if (storedAuthDetails) {
      verifyAuth(JSON.parse(storedAuthDetails));
    } else {
      setIsAuthenticated(Auth.unAuthenticated);
    }
  }, []);


  const login = (details: {
    access_token: string;
    token_type: string;
  }) => {
    verifyAuth(details);
    localStorage.setItem("authDetails", JSON.stringify(details));
    router.push("/");
  };
  const logout = () => {
    router.push("/signin");
    setIsAuthenticated(Auth.unAuthenticated);
    localStorage.removeItem("authDetails");
    setAuthDetails(null);
  };

  const value = useMemo(() => ({
    isAuthenticated,
    login,
    logout,
    authDetails,
    profile,
    subCredits,
    updateCredits
  }), [isAuthenticated, login, authDetails, profile, setProfile]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};